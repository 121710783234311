import React, { useEffect } from "react";

import Loading from '../components/loading';

const LogoutPage = () => {
  useEffect(() => {
    if (!window) {
      return;
    }

    window.history.back();
  });

  return (
    <Loading />
  );
};

export default LogoutPage;
